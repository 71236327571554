import {Component} from '@angular/core';
import {Icon, icon, latLng, MapOptions, marker, tileLayer} from "leaflet";
import {ContactConstants} from "../contact-constants";
import {faAt, faMapLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent {

  protected readonly faPhone = faPhone;
  protected readonly faAt = faAt;
  protected readonly faMapLocationDot = faMapLocationDot;

  readonly constants = ContactConstants;
  readonly coordinates = [50.29564027248803, 18.685508516389294];

  readonly mapOptions: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }),
      marker([this.coordinates[0], this.coordinates[1]],
        {
          icon: icon({
            ...Icon.Default.prototype.options,
            iconUrl: 'assets/leaflet/marker-icon.png',
            iconRetinaUrl: 'assets/leaflet/marker-icon-2x.png',
            shadowUrl: 'assets/leaflet/marker-shadow.png',
          })
        })
    ],
    zoom: 18,
    center: latLng(this.coordinates[0], this.coordinates[1])
  };
}
