import {Component} from '@angular/core';

interface Service {
  imageName: string;
  alternativeText: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss']
})
export class ServicesSectionComponent {

  readonly firstRowServices: Array<Service> = [
    {
      imageName: 'terapia-manualna.jpg',
      alternativeText: 'Ilustracja terapii manualnej',
      title: 'Terapia manualna',
      description: `Wyspecjalizowana metoda fizjoterapii oparta na biomechanice i funkcji układu ruchu człowieka. Jest
                    metodą pracy opierającą się na szczegółowym badaniu pacjenta, a następnie leczeniu manualnym.
                    Postępowanie dotyczy pracy ze wszystkimi stawami, więzadłami i mięśniami.`
    },
    {
      imageName: 'igly.jpg',
      alternativeText: 'Ilustacja suchego igłowania',
      title: 'Suche igłowanie',
      description: `Metoda fizjoterapeutyczna polegająca na nakłuciu punktu spustowego w mięśniu lub napiętego pasma
                    mięśniowego za pomocą sterylnych, jednorazowych igieł do akupunktury w celu leczenia bólu
                    mięśniowo-powięziowego i dysfunkcji w obrębie narządu ruchu.`
    },
    {
      imageName: 'powiez.jpg',
      alternativeText: 'Ilustracja terapii mięśniowo-powięziowej',
      title: 'Terapia mięśniowo-powięziowa',
      description: `Nowoczesna metoda fizjoterapii stosowana w leczeniu bólu mięśni i stawów. Masaż powięziowy przynosi
                    ulgę w bólu, poprawia zakres ruchomości tkanek oraz ogólne funkcjonowanie organizmu. Z terapii może
                    skorzystać szerokie spektrum pacjentów.`
    }
  ];
  readonly secondRowServices: Array<Service> = [
    {
      imageName: 'trening.jpg',
      alternativeText: 'Ilustracja drabinki wykorzystywanej do treningu medycznego',
      title: 'Trening medyczny',
      description: `Połączenie treningu personalnego oraz fizjoterapii. Zajęcia dedykowane są wszystkim tym, którzy chcą
                    bezpiecznie powrócić do pełnej formy sportowej po urazie/operacji i są już po okresie rehabilitacji,
                    a także tym, których celem jest poprawa efektywności treningu.`
    },
    {
      imageName: 'nmdt.jpg',
      alternativeText: 'Ilustracja diagnostyki i terapii nerwowo-mięśniowej',
      title: 'Diagnostyka i terapia nerwowo-mięśniowa',
      description: `Świetne narzędzie do określania połączeń między dysfunkcyjnymi obszarami ciała, wyznaczająca ścieżkę
                    efektywnej terapii. Jest to nerwowo-mięśniowy protokół diagnostyczno-terapeutyczny oparty na
                    manualnym testowaniu mięśni.`
    },
    {
      imageName: 'masaz.jpg',
      alternativeText: 'Ilustracja masażu klasycznego',
      title: 'Masaż klasyczny',
      description: `Masaż klasyczny to najpopularniejsza forma masażu. Stosuje się w nim różne techniki (głaskanie,
                    rozcieranie, ugniatanie, oklepywanie, wibracje) wykorzystujące siłę nacisku na mięśnie. Rozluźnia on
                    napięte mięśnie, poprawia przepływ krwi, zwiększa elastyczność mięśni i zakres ruchomości oraz
                    zmniejsza odczucia bólowe.`
    }
  ]

}
