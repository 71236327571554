import {Injectable} from '@angular/core';

export interface Section {
  id: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  private readonly aboutMeSection: Section = {
    id: 'o-mnie',
    title: 'O mnie'
  };
  private readonly firstVisitSection: Section = {
    id: 'pierwsza-wizyta',
    title: 'Pierwsza wizyta'
  };
  private readonly servicesSection: Section = {
    id: 'uslugi',
    title: 'Usługi'
  };
  private readonly gallerySection: Section = {
    id: 'galeria',
    title: 'Galeria'
  };
  private readonly reviewsSection: Section = {
    id: 'opinie',
    title: 'Opinie'
  };
  private readonly contactSection: Section = {
    id: 'kontakt',
    title: 'Kontakt'
  };

  private readonly sections: Array<Section> = [
    this.aboutMeSection,
    this.firstVisitSection,
    this.servicesSection,
    this.gallerySection,
    this.reviewsSection,
    this.contactSection
  ]

  constructor() {
  }

  getSections(): Array<Section> {
    return this.sections;
  }

}
