export class ContactConstants {

  public static readonly PHONE_NUMBER = "+48519403519";
  public static readonly PHONE_NUMBER_TEXT = "519-403-519";

  public static readonly EMAIL = "physiomove.strug@gmail.com";

  public static readonly ADDRESS_LINK = "https://maps.app.goo.gl/U686o1vScAcSySCeA";
  private static readonly ADDRESS_STREET = "ul. Zabrska 13";
  public static readonly ADDRESS_SHORT_TEXT = ContactConstants.ADDRESS_STREET + ", Gliwice";
  public static readonly ADDRESS_LONG_TEXT = ContactConstants.ADDRESS_STREET + ", 44-100 Gliwice";

  public static readonly INSTAGRAM_LINK = "https://www.instagram.com/fizjoterapiastrug";
  public static readonly FACEBOOK_LINK = "https://www.facebook.com/FizjoterapiaStrug";

}
