import {Injectable} from '@angular/core';

declare const fbq: any;

@Injectable({
  providedIn: 'root'
})
export class MetaPixelService {

  constructor() {
  }

  public trackScheduleInitiated(): void {
    this.trackCustom('ScheduleInitiated')
  }

  public trackSchedule(): void {
    this.track('Schedule')
  }

  private track(eventType: string): void {
    fbq('track', eventType);
  }

  private trackCustom(eventType: string): void {
    fbq('trackCustom', eventType);
  }

}
