import { Component } from '@angular/core';

@Component({
  selector: 'app-first-visit-section',
  templateUrl: './first-visit-section.component.html',
  styleUrls: ['./first-visit-section.component.scss']
})
export class FirstVisitSectionComponent {

}
