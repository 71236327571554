import {Injectable} from '@angular/core';
import {NgbActiveOffcanvas} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private offCanvas: NgbActiveOffcanvas | undefined;

  constructor() {
  }

  public updateRef(offCanvas: NgbActiveOffcanvas): void {
    this.offCanvas = offCanvas;
  }

  public closeSidebar(): void {
    if (this.offCanvas) {
      this.offCanvas.dismiss();
    }
  }

}
