<div class="section" id="uslugi">
  <div class="container">
    <h1 class="title text-center">Usługi</h1>
    <hr class="section-hr reveal fade-width"/>
    <div class="row">
      <div class="col-lg-4" *ngFor="let s of firstRowServices">
        <div class="card card-profile">
          <div class="card-avatar reveal zoom-in">
            <img ngSrc="assets/services/{{s.imageName}}" alt="{{s.alternativeText}}" width="120" height="120">
          </div>
          <div>
            <h2 class="card-title">{{s.title}}</h2>
            <p class="text-justify">{{s.description}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" *ngFor="let s of secondRowServices">
        <div class="card card-profile">
          <div class="card-avatar reveal zoom-in">
            <img ngSrc="assets/services/{{s.imageName}}" alt="{{s.alternativeText}}" width="120" height="120">
          </div>
          <div>
            <h2 class="card-title">{{s.title}}</h2>
            <p class="text-justify">{{s.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
