<div class="offcanvas-header">
  <button type="button" class="btn-close link" aria-label="Close" (click)="dismiss(null)"></button>
</div>
<div class="offcanvas-body">
  <a *ngFor="let s of sections" type="button" class="nav-link link" rel="tooltip" title="{{s.title}}"
     (click)="dismiss(s)" tabindex="0">
    <h2>{{ s.title }}</h2>
  </a>
</div>
<div class="offcanvas-body">
  <a type="button" class="nav-link link" rel="tooltip" title="Telefon" href="tel:{{constants.PHONE_NUMBER}}">
    <div class="row">
      <div class="col-1 text-end">
        <h2>
          <i>
            <fa-icon [icon]="faPhone"></fa-icon>
          </i>
        </h2>
      </div>
      <div class="col-11">
        <h2>{{ constants.PHONE_NUMBER_TEXT }}</h2>
      </div>
    </div>
  </a>
  <a class="nav-link link" rel="tooltip" title="E-mail" href="mailto:{{constants.EMAIL}}">
    <div class="row">
      <div class="col-1 text-end">
        <h2>
          <i>
            <fa-icon [icon]="faAt"></fa-icon>
          </i>
        </h2>
      </div>
      <div class="col-11">
        <h2>{{ constants.EMAIL }}</h2>
      </div>
    </div>
  </a>
  <a type="button" class="nav-link link" rel="tooltip" title="Adres" href="{{constants.ADDRESS_LINK}}" target="_blank">
    <div class="row">
      <div class="col-1 text-end">
        <h2>
          <i>
            <fa-icon [icon]="faMapLocationDot"></fa-icon>
          </i>
        </h2>
      </div>
      <div class="col-11">
        <h2>{{ constants.ADDRESS_LONG_TEXT }}</h2>
      </div>
    </div>
  </a>
  <div class="row">
    <div class="col-2">
      <a class="nav-link link" rel="tooltip" title="Instagram" href="{{constants.INSTAGRAM_LINK}}" target="_blank">
        <h1>
          <i>
            <fa-icon [icon]="faInstagram"></fa-icon>
          </i>
        </h1>
      </a>
    </div>
    <div class="col-2">
      <a class="nav-link link" rel="tooltip" title="Facebook" href="{{constants.FACEBOOK_LINK}}" target="_blank">
        <h1>
          <i>
            <fa-icon [icon]="faFacebookSquare"></fa-icon>
          </i>
        </h1>
      </a>
    </div>
  </div>
</div>
