<footer id="footer" class="footer navbar navbar-expand-lg fixed-bottom">
  <div class="container">
    <div class="navbar-collapse">
      <div *ngFor="let s of sections; index as i" class="navbar-nav {{i === 0 ? '' : 'ml-auto'}}">
        <div class="nav-item">
          <a class="nav-link" rel="tooltip" title="{{s.title}}" href="#{{s.id}}">
            <span>{{s.title}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
