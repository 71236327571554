// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCuSUnBATcl3i9a5oYycjz-B4FyYaR45O8",
    authDomain: "fizjoterapia-strug.firebaseapp.com",
    projectId: "fizjoterapia-strug",
    storageBucket: "fizjoterapia-strug.appspot.com",
    messagingSenderId: "174347607648",
    appId: "1:174347607648:web:a5d03b6b9dbf88953ced05",
    measurementId: "G-Z1EDML8FPX"
  }
};
