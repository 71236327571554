<div class="section" id="o-mnie">
  <div class="container">
    <div class="text-center">
      <h1 class="title">O mnie</h1>
      <hr class="section-hr reveal fade-width"/>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <p class="description text-justify">
          Jestem fizjoterapeutą pracującym w zakresie technik terapii manualnej. Swoje doświadczenie w dziedzinie
          fizjoterapii w dużej mierze opieram na współpracy ze specjalistami będąc częścią sztabu medycznego w
          piłkarskim klubie sportowym w Gliwicach oraz indywidualnej pracy z pacjentami w gabinecie
          fizjoterapeutycznym.
        </p>
        <p class="description text-justify">
          Jestem absolwentem Akademii Wychowania Fizycznego im. Jerzego Kukuczki w Katowicach ze stopniem magistra
          fizjoterapii. Ukończyłem wiele kursów z dziedziny fizjoterapii oraz regularnie uczestniczę w szkoleniach i
          konferencjach, stale poszerzając swój warsztat i wiedzę.
        </p>
        <br/>
        <div>
          <p class="description">Pracuję z pacjentami przy:</p>
          <br/><br/>
          <div ngbAccordion [closeOthers]="true">
            <div ngbAccordionItem *ngFor="let item of accordionItems">
              <div ngbAccordionHeader aria-level="1">
                <button ngbAccordionButton>{{ item.title }}</button>
              </div>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <p class="description">{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-5 mt-lg-0 text-center">
        <img ngSrc="assets/o-mnie.jpeg" class="img-rounded" alt="Zdjęcie profilowe" height="667" width="440">
      </div>
    </div>
  </div>
</div>
